import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, Router } from "@angular/router";
import { Observable } from "rxjs/internal/Observable";

@Injectable({
  providedIn: "root",
})
export class AuthService {
  constructor(private router: Router) {}
  canActivate(): Observable<boolean> | Promise<boolean> | boolean {
    if (localStorage.getItem("isLoggedin")) {
      return true;
    }
    this.router.navigate(["/"]);
    return false;
  }

  canActivateChild(route: ActivatedRouteSnapshot) : Observable<boolean> | Promise<boolean> | boolean{
    const user = localStorage.getItem("isLoggedin");
    if (user) {
        const { roles } = route.data;
        const userRole= Number(localStorage.getItem("userRole"))
        let roleUser;
        if( userRole === 0){
          roleUser ='Service Owner'
        }else if(userRole === 1){
          roleUser ='Factory Lead'
        }
        else if(userRole === 2){
          roleUser ='Admin'
        }
        else if(userRole === 3){
          roleUser ='Global Factory Lead'
        }
        else if(userRole === 4){
          roleUser ='Service Portfolio Owner'
        }else{
          roleUser =''
        }
        console.log("roles",roles)
        console.log("roleUser",roleUser)
        if(roles!=undefined){
        var check= roles.includes(roleUser)
        console.log("check",check)
        // console.log("operator",roles && roles != userRole )
        if (!check) {
          console.log("userRoleDoesntMatch")
            // role not authorized so redirect to Acces Denied Page
            this.router.navigate(['/layout/unauthorized']);
            return false;
        }
      }
        // authorized so return true
        return true;
    }
    this.router.navigate(["/"]);
    return false;
}
}
