import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class BaseUrl {
  apiurl:any;
  constructor() { 
   this.apiurl= localStorage.getItem("ApiUrl" ) ;
  }
  

  public  get Login(): string { return this.apiurl! + 'users/authenticate' };
  public  get ListUser(): string { return this.apiurl!+ 'users'};
  public  get AddUser(): string { return this.apiurl!+ 'users/register' };
  public  get DeleteUser(): string { return this.apiurl! +'users'};
  public  get UserByID(): string { return this.apiurl! +'users'};
  public  get UpdateUserID(): string { return this.apiurl! +'users'};
  public  get changeUserPassword(): string { return this.apiurl! +'users/changePassword'};
  public  get ServiceData(): string { return this.apiurl!+'api/v1/platform/bpm/services'};
  public  get TaskData(): string { return this.apiurl!+'api/v1/platform/bpm/service/'};
  public  get BPMSaveData(): string { return this.apiurl!+'api/v1/platform/data/save'};
  public  get Service(): string { return this.apiurl!+'api/v1/platform/data/services'};
  public  get ServiceStatusDone(): string { return this.apiurl!+'api/v1/platform/data/service/statusDone'};
  public  get OrderService(): string { return this.apiurl!+'api/v1/platform/jira/load/data'};
  public  get OrderCreate(): string { return this.apiurl!+'api/v1/platform/jira/order/create'};
  public  get OrderHistory(): string { return this.apiurl!+'api/v1/platform/jira/order/history'};
  public  get ExportOrderHistory(): string { return this.apiurl!+'api/v1/platform/jira/order/export'};
  public  get CustomerDataService(): string { return this.apiurl!+'api/v1/platform/jira/data/get/customers'};
  public  get PodActivityService(): string { return this.apiurl!+'api/v1/platform/jira/data/get/podactivities'};
  public  get CustomerPodActivityService(): string { return this.apiurl!+'api/v1/platform/jira/data/get/podactivitycustomers'};
  public  get DealPodActivityService(): string { return this.apiurl!+'api/v1/platform/jira/data/get/podactivityopp'};
  public  get DealPodDetailService(): string { return this.apiurl!+'api/v1/platform/jira/data/get/podactivitydetails'};
  public  get GetAssetAllData(): string { return this.apiurl! +'api/v1/platform/asset'};
  public  get GetAssetSearchAllData(): string { return this.apiurl! +'api/v1/platform/asset/search'};
  public  get GetStoryRole(): string { return this.apiurl! +'api/v1/platform/jira/jiraRoles'};
  public  get GetAssetDetailsData(): string { return this.apiurl!+'api/v1/platform/asset/'};
  public  get DeleteAssetData(): string { return this.apiurl!+'api/v1/platform/asset/'};
  public  get AddAssetData(): string { return this.apiurl! +'api/v1/platform/asset'};
  public  get OrderHistoryExport(): string { return this.apiurl!+'api/v1/platform/jira/order/export?createdBy='};
  public  get GetCatalogDataService(): string { return this.apiurl!+'api/v1/platform/catalog/search'};
  public  get AddCatalogDataService(): string { return this.apiurl!+'api/v1/platform/catalog'};
  public  get DeleteCatalogDataService(): string { return this.apiurl!+'api/v1/platform/catalog/'};
  public  get UpdateCatalogDataService(): string { return this.apiurl!+'api/v1/platform/catalog'};
  public  get GetCatalogDetailDataService(): string { return this.apiurl!+'api/v1/platform/catalog/'};
  public  get LINKASSETCatalogDataService(): string { return this.apiurl!+'api/v1/platform/asset/task/link'};
  public  get PROCESSBYCODEASEET(): string { return this.apiurl!+'api/v1/platform/data/'};
  public  get LINKEDASSETDATA(): string { return this.apiurl!+'api/v1/platform/asset/task/'};
  public  get CHECPROCESSTASKDATAEXIST(): string { return this.apiurl!+'api/v1/platform/data/exist'};
  public  get GetFeedbackData(): string { return this.apiurl!+'api/v1/platform/jira/data/feedback/search'};
  public  get AddFeedBackData(): string { return this.apiurl! +'api/v1/platform/jira/data/save/feedback'};
  public  get GetFeedbackDetailData(): string { return this.apiurl! +'api/v1/platform/jira/data/feedback/'};
  public  get GetListOfServiceName(): string { return this.apiurl!+'api/v1/platform/catalog/services/done'};
  public  get GetJiraPhase(): string { return this.apiurl!+'api/v1/platform/jira/data/get/phases'};







  
}

