import { Component } from '@angular/core';
import { Router } from "@angular/router";

@Component({
  selector: 'app-not-found',
  templateUrl: './not-found.component.html',
  styleUrls: ['./not-found.component.css']
})
export class NotFoundComponent {
  constructor(
    public router: Router,
  ) {}
  ngOnInit() {
  var token=localStorage.getItem('token')
  if(token){
    this.router.navigate(["/layout/404"]);
  }
  }

}
