import { Injectable, APP_INITIALIZER } from '@angular/core';
import { Observable } from 'rxjs';
import {HttpClient} from '@angular/common/http'

import { environment } from '../../environments/environment'; //path to your environment files
import {map} from 'rxjs/operators';


@Injectable()
export class ConfigService {
    private _config!: Object;
    private _env!: string;
    constructor(private _http: HttpClient) { }
    load() {
      var apiUrl;
        return new Promise((resolve, reject) => {
            console.log("environment.production",environment.production)
            // if (environment.production){
            // this._env = 'production';
              apiUrl=this._http.get('../../assets/config.json')
            // }else{
            //   this._env = 'development';
            //   apiUrl=this._http.get('../../assets/development.json')
            //  }
                 apiUrl
                .pipe(map(res => res))
                .subscribe((data) => {
                  console.log("data",data)
                  if(data && data.apiUrl){
                  localStorage.setItem("ApiUrl", data.apiUrl);
                  }
                  this._config = data;
                    resolve(true);
                },
                (error: any) => {
                    console.error(error);
                });
        });
        
    }
}

export function ConfigFactory(config: ConfigService) {
  console.log("config",config)
    return () => config.load();
}


export function init() {
    return {
        provide: APP_INITIALIZER,
        useFactory: ConfigFactory,
        deps: [ConfigService],
        multi: true
    }
}

const ConfigModule = {
    init: init
}
export { ConfigModule}