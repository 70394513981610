export const Regex ={

    pattern: { 
        // "EMAIL": /^\w+([\.-]?\w+)*@\w+([\-]?\w+)*(\.\w{2,3})+$/,
        "EMAIL" :/^([a-zA-Z0-9_\-\.]+)@([a-zA-Z0-9\-]+\.)+([a-zA-Z0-9\-\.]+)*$/,
        "SERVICECATALOGEMAIL": /^([a-zA-Z0-9_\-\.]+)@([a-zA-Z0-9\-]+\.)+([a-zA-Z0-9\-\.]+)+([;,/]([a-zA-Z0-9_\-\.]+)@([a-zA-Z0-9\-]+\.)+([a-zA-Z0-9\-\.]+))*$/,
        "MAXLENGTH":15,
        "MINLENGTH":2,
        "MAXLENGTHUSERNAME":50,
        "USERNAMEUSERMANAGEMENT":/^[a-zA-Z0-9]*$/,
        // "USERNAMEUSERMANAGEMENT": /^(?!.*\s$)(?![\s\S]*\s\s)[a-zA-Z0-9\s]*[a-zA-Z0-9]$/,
        "EMAILUSERNAMEREGEX": /^(?:[a-zA-Z0-9]*[a-zA-Z0-9]|[a-zA-Z0-9_\-\.]+@[a-zA-Z0-9\-]+\.[a-zA-Z0-9\-\.]+)$/,
        "USERNAME":/^[ A-Za-z0-9_@./#&+-/'/"]*$/,
        "Password": /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?!.*\s).{6,}$/,
        "PassminLength": 6,
        "PassmaxLength": 10,
        "usernameminLength": 2,
        "usernamemaxLength": 15,
        "usernameEmailmaxLength": 80,
        "serviceOrderMaxLength":25,
        "serviceOrderMinLength":2,
        "MAXLENGTHASSET":30,
        "MAXLENGTHASSETNAME":255,
        "MAXLENGTHASSETVERSION":20,
        "MAXLENGTHASSETDESCRIPTION":50,
        "MAXLENGTHASSETTEXTAREA":1000,
        "MAXLENGTHAPPLICATIONNAME":100,
        "ASSETPARAMDEFAULTVALUEMINLENGTH":1,
       /// "CATALOG":/^[A-Za-z0-9_@./#&+-/'/()"_]*([A-Za-z0-9_@./#&+-/'/()"]+)*$/,
        "CATALOG":/^([a-zA-Z0-9.&-/()]+\s)*[a-zA-Z0-9.&-/()]+$/,
        // "APPLICATIONNAME":/^([a-zA-Z0-9_@.&-/()]+\s)*[a-zA-Z0-9_@.&-/()]+$/,
        "APPLICATIONNAME":/^([a-zA-Z0-9_.-]+\s)*[a-zA-Z0-9_.-]+$/,
        "CATALOGPRODUCTMANAGER":/^([a-zA-Z./()]+\s)*[a-zA-Z./()]+$/,
       // "CATALOGSERVICECODE":/^[A-Za-z0-9_@./#&+-/'/()"]*$/,
        "CATALOGSERVICECODE":/^[A-Za-z0-9-]*$/,
        "CATALOGMAXLENGTH":50,
        "CatalogServiceNameMaxlength":100,
        "CATALOGVERSIONMAXLENGTH":20,
        "CATALOGVERSIONMINLENGTH":1,
        "SERVICENUMBERNOMAXLENGTH" :2,
        //"SERVICENUMBERREGEX": /^[0-9_]*$/,
        "SERVICENUMBERREGEX":/^([0-9]\d|\d)(\.\d{0,2})?$/,
        // "GTMDOCLINK":/^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/,
       // "GTMDOCLINK":/^\S+$/,
        "GTMDOCLINK":/^https?:\/\/[^\s/$.?#].[^\s]*$/,
        "EmailmaxLength": 50,
        "ASSET":/^([a-zA-Z0-9_@.&-/()]+\s)*[a-zA-Z0-9_@.&-/()]+$/,
        "AssetName":/^\S(?:.*\S)?$/,
        "ASSETTEXTAREA":/^[^\s]+(\s+[^\s]+)*$/,
        "FEEDBACKTEXTAREA":/^[^\s]+(\s+[^\s]+)*$/,
        "ASSETVERSION":/^[a-zA-Z0-9]+(\.[a-zA-Z0-9]+)*$/,
        // "ASSETPARAMKEY":/^[a-zA-Z]+[0-9]*(?: [a-zA-Z]+[0-9]*)*$/,
        "ASSETPARAMKEY": /^[a-zA-Z][a-zA-Z0-9]*[a-zA-Z0-9]$/,
        "ASSETPARAMDEFAULTVALUE": /^(?=[a-zA-Z0-9!@#$%^&*()\-_+=|\\:;"'<>,.?/])[a-zA-Z0-9!@#$%^&*()\-_+=|\\:;"'<>,.?/ ]+(?<=[a-zA-Z0-9!@#$%^&*()\-_+=|\\:;"'<>,.?/])$/,
        "ASSETNAME":/^[a-zA-Z0-9-_\.@][a-zA-Z0-9-_\.@ ]*[a-zA-Z0-9-_\.@]$/,
        "STORYPOINT":5,
        "STORYPOINTROLE":50,
        "REPORTERNAME": /^[a-zA-Z0-9]+( [a-zA-Z0-9]+)*$/,
        "MAXLONGTEXTAREAFEEDBACK":2000,
        "MAXSHORTTEXTAREAFEEDBACK":1000







        



        



    },
    paginator: {
        "COUNT": 10,
        "PAGE": 0
    },
    pageSizeCustomerData:{
        "SIZE": 100000,
        "PAGENUMBER": 0

    }
}

