import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { BaseUrl } from 'src/app/shared/utils/base-url';
import { HttpClient } from  '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class LoginService {

  constructor(private http: HttpClient ,private baseUrl:BaseUrl) { }

  login(data: any): Observable<any> {
    console.log("BaseUrl.Login",this.baseUrl)
      return this.http.post(this.baseUrl.Login, data);
}
}
