import { NgModule,APP_INITIALIZER } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { FormsModule ,ReactiveFormsModule} from '@angular/forms';
import { HttpClient, HttpClientModule,HTTP_INTERCEPTORS } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { LoginComponent } from './login/login.component';
import { ToastrModule } from 'ngx-toastr';
import { AuthService } from './shared/auth/auth.service';
import { NgxSpinnerModule } from "ngx-spinner";
import { NotFoundComponent } from './not-found/not-found.component';  
import { LoginGaurdService } from './shared/auth/login-gaurd.service';
import {ConfigModule,ConfigService} from '../app/providers/app-config.service'
import { TokenInterceptor } from './shared/Interceptor/token.interceptor';
import { TooltipModule } from 'primeng/tooltip';

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    NotFoundComponent,
    
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    BrowserAnimationsModule,
    NgxSpinnerModule,
    ToastrModule.forRoot({
      timeOut: 2000,
    }), 
    TooltipModule
  ],
  providers: [AuthService,LoginGaurdService,ConfigService,
    ConfigModule.init(),{
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true
    }],
  bootstrap: [AppComponent]
})
export class AppModule { }
