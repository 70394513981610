export const environment = {
    // env : 'local',
    production: false,
  
    // apiUrl : 'http://localhost:4000/users',
    // bpmServiceUrl : 'http://localhost:8089/api/v1/platform/bpm',
    // bpmTaskUrl : 'http://localhost:8089/api/v1/platform/bpm/service/',
    // dbServiceUrl : 'http://localhost:9080/api/v1/platform/data',
    // jiraServiceUrl : 'http://localhost:8095/api/v1/platform/jira'

};

// export const environment = {
//     production: true,
//     apiUrl : 'https://cfp-usersvc.cnapps.ocp-noi-dev-cluster-labs.hclcnlabs.com/users',
//     bpmServiceUrl : 'https://cfp-bpmsvc.cnapps.ocp-noi-dev-cluster-labs.hclcnlabs.com/api/v1/platform/bpm',
//     bpmTaskUrl : 'https://cfp-bpmsvc.cnapps.ocp-noi-dev-cluster-labs.hclcnlabs.com/api/v1/platform/bpm/service/',
//     dbServiceUrl : 'https://cfp-dbsvc.cnapps.ocp-noi-dev-cluster-labs.hclcnlabs.com/api/v1/platform/data',
//     jiraServiceUrl : 'https://cfp-jirasvc.cnapps.ocp-noi-dev-cluster-labs.hclcnlabs.com/api/v1/platform/jira'
// };

//New Ingris URL



// export const environment = {
//     production: true,
//     apiUrl : 'https://cfp-svc.cnapps.ocp-noi-dev-cluster-labs.hclcnlabs.com/users',
//     bpmServiceUrl : 'https://cfp-svc.cnapps.ocp-noi-dev-cluster-labs.hclcnlabs.com/api/v1/platform/bpm',
//     bpmTaskUrl : 'https://cfp-svc.cnapps.ocp-noi-dev-cluster-labs.hclcnlabs.com/api/v1/platform/bpm/service/',
//     dbServiceUrl : 'https://cfp-svc.cnapps.ocp-noi-dev-cluster-labs.hclcnlabs.com/api/v1/platform/data',
//     jiraServiceUrl : 'https://cfp-svc.cnapps.ocp-noi-dev-cluster-labs.hclcnlabs.com/api/v1/platform/jira'
    
// };

