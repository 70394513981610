import { Injectable } from '@angular/core';
import { Router } from "@angular/router";
import { Observable } from "rxjs/internal/Observable";

@Injectable({
  providedIn: 'root'
})
export class LoginGaurdService {
  constructor(private router: Router) {}
  canActivate(): Observable<boolean> | Promise<boolean> | boolean {
    if (localStorage.getItem("isLoggedin")) {
      if( localStorage.getItem("userRole") =='0'){
        this.router.navigate(["/layout/asset-management"]);
      }
      if( localStorage.getItem("userRole") =='1'){
        this.router.navigate(["/layout/asset-management"]);
      }
      if( localStorage.getItem("userRole") =='2'){
        this.router.navigate(["/layout/user-management"]);
      }
      if( localStorage.getItem("userRole") =='3'){
        this.router.navigate(["/layout/user-management"]);
      }
      if( localStorage.getItem("userRole") =='4'){
        this.router.navigate(["/layout/user-management"]);
      }
      return false;
    }
    else{
      return true

    }
  }
}
