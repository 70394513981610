import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LoginComponent } from './login/login.component';
// import { SidenavbarComponent } from './layout/sidenavbar/sidenavbar.component';
import { AuthService } from './shared/auth/auth.service';
import { NotFoundComponent } from './not-found/not-found.component';
import { LoginGaurdService } from './shared/auth/login-gaurd.service';

const routes: Routes = [    
  { 
    path: 'layout',
    loadChildren: () => import('./layout/layout.module').then(x => x.LayoutModule),canActivate: [AuthService]
 },
  { path: '', component: LoginComponent ,canActivate: [LoginGaurdService]},
  // { path: 'side', component: SidenavbarComponent },
  { path: '404', component: NotFoundComponent },
  { path: '**', component: NotFoundComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
