// import { Injectable } from '@angular/core';
// import {
//   HttpRequest,
//   HttpHandler,
//   HttpEvent,
//   HttpInterceptor
// } from '@angular/common/http';
// import { Observable } from 'rxjs';

// @Injectable()
// export class TokenInterceptor implements HttpInterceptor {

//   constructor() { /* can be initialized with auth service */ }

//   intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    
//     request = request.clone({
//       setHeaders: {
//         Authorization: `Bearer ${localStorage.getItem("token")}`
//       }
//     });
//     return next.handle(request);
//   }
// }

import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Router } from '@angular/router';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {

  constructor(private router: Router) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const token = localStorage.getItem("token");
    if (token) {
      request = request.clone({
        setHeaders: {
          Authorization: `Bearer ${token}`
        }
      });
    }
    return next.handle(request).pipe(
      catchError((error: HttpErrorResponse) => {
        return this.handleAuthError(error);
      })
    );
  }

  private handleAuthError(error: HttpErrorResponse): Observable<any> {
    console.error('Error occurred:', error);
    if (error?.status === 401) {
      this.logout();
      const errorMessage = 'Please login again. Your session has expired.';
      const customError = new HttpErrorResponse({
        error: { message: errorMessage },
        status: 401
      });
      
      return new Observable(observer => {
        observer.error(customError);
      });
    }
    throw error;
  }

  logout() {
    if (localStorage.getItem('token')) {
      localStorage.removeItem('email');
      localStorage.removeItem('token');
      localStorage.removeItem('username');
      localStorage.removeItem('userRole');
      localStorage.removeItem('isLoggedin');
      localStorage.removeItem('userId');
      this.router.navigate(['/']);
    }
  }
}
