<div class="login-page">
    <div class="row justify-content-md-center1">
      <div class="col-md-8 col-sm-6 col-lg-4 offset-sm-3 offset-md-2 offset-lg-4 ">
        <div class="logInBox">
          <img class="hclImage" src="../../assets/hcllogo.svg" />
          <h4 class="login_header">Sign in to Cloud Factory Platform </h4>
          <form>
            <form ng role="form" [formGroup]="LoginForm">
              <div class="form-content">
                <div class="form-group alignInputLabel">
                  <input type="text" formControlName="username" pTooltip="Username can accept Alphanumeric characters with no white spaces allowed at start or end E.g. John / Email ID can accept only specified format john@domainname.com" tooltipPosition="right" class="form-control input-underline input-lg" placeholder="Username / Email ID">
                                <p class="form_error"
                                    *ngIf="LoginForm.controls['username'].dirty && LoginForm.controls['username'].hasError('required')">
                                    Please enter Username / Email ID.</p>
                                <p class="form_error"
                                    *ngIf="LoginForm.controls['username'].dirty &&LoginForm.controls['username'].hasError('maxlength')">
                                     Maximum 80 characters allowed.</p>
                                <p class="form_error"
                                    *ngIf="LoginForm.controls['username'].dirty &&LoginForm.controls['username'].hasError('minlength')">
                                     Minimum 2 characters required.</p>
                                     <p class="form_error" *ngIf="LoginForm.controls['username'].dirty && LoginForm.controls['username'].hasError('pattern') && !LoginForm.controls['username'].hasError('required')&& !LoginForm.controls['username'].hasError('minlength') && !LoginForm.controls['username'].hasError('maxlength')"> Please enter valid Username / Email ID.</p>

                </div>
                <div class="form-group alignInputLabel">
                  <input type="Password" class="form-control input-underline input-lg" formControlName="password" placeholder="Password" pTooltip="Password should contain Alphanumeric characters with at least one lower, uppercase letter and Special characters, no white spaces are allowed at start or end. E.g. John@123" tooltipPosition="right">
                    <p class="form_error" *ngIf="LoginForm.controls['password'].dirty && LoginForm.controls['password'].hasError('required')">Please enter Password.</p>
                    <p class="form_error" *ngIf="LoginForm.controls['password'].hasError('maxlength')"> Maximum 10 characters allowed.</p>
                    <p class="form_error" *ngIf="LoginForm.controls['password'].hasError('minlength')&& !LoginForm.controls['password'].hasError('required') "> Minimum 6 characters required.</p>
                    <p class="form_error" *ngIf="LoginForm.controls['password'].dirty && LoginForm.controls['password'].hasError('pattern') && !LoginForm.controls['password'].hasError('required')&& !LoginForm.controls['password'].hasError('minlength') && !LoginForm.controls['password'].hasError('maxlength')"> Please enter valid Password.</p>
                </div>
                <div class="footerBtn">
                  <button class="btn btn-primary btnclass" [disabled]="!LoginForm.valid" (click)="login()"> Login</button>
                </div>
              </div>
            </form>
          </form>
        </div>
      </div>
    </div>
    
  </div>
  <ngx-spinner class="spinner" size="default" type="ball-spin-clockwise">
    <p class="common-msg">Please Wait... </p>
  </ngx-spinner>